@font-face {
    font-family: "Marianne";
    src: url("./assets/fonts/marianne-regular-webfont.woff");
}

html * {
    font-family: Marianne, sans-serif !important;
}

html, body {
    max-width: 100%;
    min-height: 100vh;
    overflow: hidden !important;
    padding: 0 !important;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border: none;
    z-index: 2147483647;
}

#root {
    height: 100vh;
    max-height: 100vh !important;
    display: flex !important;
    flex-direction: column !important;
}

* {
    -webkit-tap-highlight-color: transparent;
}

button {
    text-transform: none !important;
}

